import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import AppContext from "../store/AppContext";
import { accessEmailValidation, apiForgotPasswordValidation, setUpdateForgotPasswordStatusIdle, setValidateForgotPasswordStatusIdle, updatedPasswordDetails } from "../redux/features/user/user.slice";
import { Box, Button, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import error from "../assets/images/error.png";
import { clearStorage } from "../store/storage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as SVGAautiLogo } from "../assets/images/aautischoolLogo.svg"
import { toast } from "react-toastify";
import { domainBaseUrl } from "../config/config"

var qs = require('qs');

const modalStyle = {
  position: 'absolute',
  // marginTop: 8,
  top: "30%",
  left: '50%',
  transform: 'translate( -50%,0)',
  width: '100%',
  maxWidth: '520px',
  // maxWidth: '422px',
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const errModalStyle = {
  position: 'absolute',
  // marginTop: 8,
  top: "30%",
  left: '50%',
  transform: 'translate( -50%,0)',
  width: '100%',
  maxWidth: '520px',
  // maxWidth: '422px',
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const ChangePasswordContainer = ({ props }: any) => {
  const dispatch = useAppDispatch();
  console.log("enterednepassword")
  const signupUser = useAppSelector((state: any) => state.signupUser)
  const user = useAppSelector((state: any) => state.user)
  const hostname = window && window.location && window.location.hostname;
  const [newPassword, setNewPassword] = useState<any>("")
  const [confirmPassword, setConfirmPassword] = useState<any>("")
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<any>(true)
  const [password, setPassword] = useState<any>("")
  const [showPassword, setShowPassword] = useState<any>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<any>(false)
  const [passwordError, setPasswordError] = useState<any>(false)
  const [passwordError2, setPasswordError2] = useState<any>(false)
  const [confirmPasswordError2, setConfirmPasswordError2] = useState<any>(false)
  const [showConfirmPasswordErrr, setShowConfirmPasswodErr] = useState<any>(false)
  const updateForgotPasswordObj = useAppSelector((state: any) => state.user.updateForgotPasswordDetails)
  console.log("iiiii", updateForgotPasswordObj)
  const [showErrModal, setShowErrModal] = useState<any>(false)
  const userDetails = user?.forgotPasswordValidationObj?.data?.result

  console.log(userDetails, "userdetailsss")
  useEffect(() => {
    const searchParams = qs.parse(window.location.search);
    console.log(searchParams, "searchParamsmm")
    dispatch(apiForgotPasswordValidation({
      validationCode: searchParams["?forgotpasswordverify"]
      // validationCode:"EshiONUuYw"
    }))
  }, [])

  const Loading = () => {
    return (

      <div className="w-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "rgba(250,250,250,0.6)", position: "absolute", height: "70vh", zIndex: 1
        }}
      >
        <div className="spinner-border text-primary">
        </div>
      </div>
    )
  }

  const handlePasswordChange = (e: any) => {
    console.log(e.target.value, "evveve")
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    const minLengthRegex = /^.{8,}$/;          // At least 8 characters
    const maxLengthRegex = /^.{1,20}$/;        // Maximum 20 characters
    const firstLetterCapitalRegex = /^[A-Z]/;  // First letter as a capital letter
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;  // At least one special character

    const isMinLengthValid = minLengthRegex.test(newPassword);
    const isMaxLengthValid = maxLengthRegex.test(newPassword);
    const isFirstLetterCapitalValid = firstLetterCapitalRegex.test(newPassword);
    const isSpecialCharacterValid = specialCharacterRegex.test(newPassword);
    const emailRegexValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (isMinLengthValid && isMaxLengthValid) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  }


  const handleBlur = (fieldName: any) => {
    switch (fieldName) {
      case "newPassword":
        if (newPassword === "") {
          setPasswordError2("Please enter your new password");
        } else {
          setPasswordError2("");
        }
        break;
      case "confirmPassword":
        if (confirmPassword === "") {
          setConfirmPasswordError2("Please confirm your password");
        } else {
          setConfirmPasswordError2("");
        }
        break;
      default:
        break;
    }
  };

  const handleConfirmPassword = (e: any) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);

    if (newPassword !== confirmPassword) {
      setShowConfirmPasswodErr(true);
    } else {
      setShowConfirmPasswodErr(false);
    }
  }


  const onClickBtn = () => {
    dispatch(updatedPasswordDetails({
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      email: userDetails?.email
    }))
  }

  useEffect(() => {
    if (updateForgotPasswordObj?.status == "succeeded") {
      console.log("succeededded")
      toast("Password Changed Successfully!")
      if (hostname.includes('dev')) {
        window.open(`https://dev.${domainBaseUrl}`, "_self")
      } else if (hostname.includes('qa')) {
        window.open(`https://qa.${domainBaseUrl}`, "_self")

      } else if (hostname.includes('prod')) {
        window.open(`https://prod.${domainBaseUrl}`, "_self")

      } else if (hostname.includes('uat')) {
        window.open(`https://uat.${domainBaseUrl}`, "_self")

      } else {
        window.open(`https://www.${domainBaseUrl}`, "_self")

      }
      dispatch(setUpdateForgotPasswordStatusIdle())
    }
  }, [updateForgotPasswordObj?.status])


  useEffect(() => {
    if (user?.forgotPasswordValidationObj?.status == "succeeded") {
      console.log(user?.forgotPasswordValidationObj?.status, "useefectstauss")
      setShowChangePasswordModal(true)
      setShowErrModal(false)
      dispatch(setValidateForgotPasswordStatusIdle())
    } else if (user?.forgotPasswordValidationObj?.status == "failed") {
      console.log(user?.forgotPasswordValidationObj?.status, "useefect22stauss")
      setShowErrModal(true)
      setShowChangePasswordModal(false)
      dispatch(setValidateForgotPasswordStatusIdle())
    }
  }, [user?.forgotPasswordValidationObj?.status])


  const clickOkBtn = () => {
    setShowErrModal(false)
    clearStorage()
    if (hostname.includes('dev')) {
      window.open(`https://dev.${domainBaseUrl}`, "_self")
    } else if (hostname.includes('qa')) {
      window.open(`https://qa.${domainBaseUrl}`, "_self")

    } else if (hostname.includes('prod')) {
      window.open(`https://prod.${domainBaseUrl}`, "_self")

    } else if (hostname.includes('uat')) {
      window.open(`https://uat.${domainBaseUrl}`, "_self")

    } else {
      window.open(`https://www.${domainBaseUrl}`, "_self")

    }
  }

  return (
    <>
      {updateForgotPasswordObj?.status == "loading" && Loading}
      {showChangePasswordModal && <Modal open={showChangePasswordModal}>
        <Box sx={[modalStyle, { height: "40vh" }]}>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", p: 5 }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <SVGAautiLogo style={{ height: "50px", width: "90px" }} />
            </div>
            <div style={{ marginTop: "10px", marginBottom: "8px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", fontFamily: "sans-serif", fontSize: "12px" }}>New Password</div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size='small'
              fullWidth
              value={newPassword}
              inputProps={{ maxLength: 40 }}
              InputLabelProps={{ style: { fontSize: 14, fontFamily: "sans-serif", color: "rgba(167, 167, 167, 0.8)" } }}
              label="enter password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: <InputAdornment position="end">{showPassword ? <Visibility fontSize="small" onClick={() => setShowPassword(false)} /> : <VisibilityOff fontSize="small" onClick={() => setShowPassword(true)} />}</InputAdornment>,
              }}
              onChange={handlePasswordChange}
              onBlur={() => handleBlur("newPassword")}
            />
            {passwordError && !passwordError2 && <Typography sx={{ color: "red", fontSize: "12px", paddingTop: "5px", textAlign: "left" }}>password length between 8 to 20</Typography>}
            <div style={{ marginTop: "10px", marginBottom: "8px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", fontFamily: "sans-serif", fontSize: "12px" }}>Confirm Password</div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size='small'
              fullWidth
              value={confirmPassword}
              inputProps={{ maxLength: 40 }}
              InputLabelProps={{ style: { fontSize: 14, fontFamily: "sans-serif", color: "rgba(167, 167, 167, 0.8)" } }}
              label="enter confirm password"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: <InputAdornment position="end">{showConfirmPassword ? <Visibility fontSize="small" onClick={() => setShowConfirmPassword(false)} /> : <VisibilityOff fontSize="small" onClick={() => setShowConfirmPassword(true)} />}</InputAdornment>,
              }}
              onChange={handleConfirmPassword}
              onBlur={() => handleBlur("confirmPassword")}
            />
            {!showConfirmPasswordErrr && !confirmPasswordError2 && newPassword !== confirmPassword && confirmPassword !== "" && <Typography sx={{ color: "red", fontSize: "10px", padding: "10px", textAlign: "left" }}>Passwords do not match</Typography>}
            {confirmPasswordError2 && <Typography sx={{ color: "red", fontSize: "10px", padding: "10px", textAlign: "left" }}>{confirmPasswordError2}</Typography>}
            {showConfirmPasswordErrr && !confirmPasswordError2 && <Typography sx={{ color: "red", fontSize: "10px", paddingTop: "5px", textAlign: "left" }}>New Password and Confirm Passwords Shouldbe same</Typography>}
            <Button variant="contained" style={{ color: "#fff", fontFamily: "sans-serif", textTransform: "none", backgroundColor: "#3166ba", width: "90px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "center", cursor: "pointer", paddingBottom: "5px", marginTop: "20px" }} onClick={onClickBtn}>Submit</Button>
          </Box>
        </Box>
      </Modal>
      }
      {showErrModal && <Modal open={showErrModal}>
        <Box sx={[errModalStyle, { height: "35vh" }]}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", flexDirection: "column", p: 5 }}>
            <img src={error} style={{ height: "100px", width: "120px", textAlign: "center" }} />
            <Typography paragraph
              mt={1}
              sx={{ fontSize: 17, alignSelf: "center", textAlign: "center", color: "black" }}>
              {/* {user?.accessMailValidationObj?.error} */}
              This link is not valid,Please check your admin
            </Typography>
            <Button variant="contained" style={{ color: "#fff", fontFamily: "sans-serif", textTransform: "none", backgroundColor: "#3166ba", width: "90px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "center", cursor: "pointer", paddingBottom: "5px" }} onClick={clickOkBtn}>OK</Button>
          </Box>
        </Box>
      </Modal>}
    </>
  )
}


export default ChangePasswordContainer