import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import AppContext from '../store/AppContext';
import { Alert } from '@mui/material';
import { checkSignupUserLogin } from '../redux/features/signup-student/student.slice';
import {domainBaseUrl} from "../config/config"
import Loading from '../components/Loading';


var qs = require('qs');


export default function SignupStudentLogin({props}: any) {

    const dispatch = useAppDispatch();
    const appContext = useContext(AppContext);
    const [ssoError, setSSOError] = useState("N")

    const signupUser = useAppSelector((state : any) => state.signupUser)
    const hostname = window && window.location && window.location.hostname;

    useEffect(() => {
        const searchParams = qs.parse(window.location.search);
        dispatch(checkSignupUserLogin({
            classCode: searchParams["classCode"],
            userId: searchParams["?userId"]
        }))
    }, [])

    useEffect(() => {
       
        if (signupUser.status === 'loading') {
            <Loading/>
        }else  if(signupUser.status === 'succeeded' && signupUser.signupUserProfile.status === 'active') {
            const userProfile = signupUser.signupUserProfile
            if((userProfile.role).toLowerCase() === "teacher"){
                const userDetails = { ...userProfile, selectedSection: userProfile?.sections ? userProfile?.sections[0] : {} }
                appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/teacher`,"_self")
                } else {
                    window.open(`https://www.${domainBaseUrl}/teacher`,"_self")
                }
            }else if((userProfile.role).toLowerCase() === "student"){
                appContext?.setStudentUserDetails(userProfile, "studentUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/student`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/student`,"_self")
        
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/student`,"_self")
        
                }  else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/student`,"_self")
        
                } else {
                    window.open(`https://www.${domainBaseUrl}/student`,"_self")
                }
            }else if((userProfile.role).toLowerCase() === "parent"){
                appContext?.setParentUserDetails(userProfile, "parentUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/parent`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/parent`,"_self")
        
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/parent`,"_self")
        
                } else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/parent`,"_self")
                } else {
                    window.open(`https://www.${domainBaseUrl}/parent`,"_self")
                }
            }
        }else if (signupUser.status === "failed"){
            setSSOError("Y")
        }
    }, [signupUser])

    const error = <Alert severity="error">We are unable to login now — check with your admin!</Alert>
    
    return(
        <div>
            {ssoError === 'Y' ? error : ''}
        </div>
    )
}