import { useContext, useEffect, useState } from 'react';
import { checkClassLikSSO } from '../redux/features/classlink/sso.slice';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import AppContext from '../store/AppContext';
import { Alert } from '@mui/material';
import {domainBaseUrl} from "../config/config"

var qs = require('qs');

export default function ClasslinkSSO({props}: any) {

    const dispatch = useAppDispatch();
    const appContext = useContext(AppContext);
    const [ssoError, setSSOError] = useState(false)

    const classlinkUser = useAppSelector((state : any) => state.classlinkUser)
    const hostname = window && window.location && window.location.hostname;

    useEffect(() => {
        const classLinkSSO = qs.parse(window.location.search);
        dispatch(checkClassLikSSO(classLinkSSO["?code"]))
    }, [])

    useEffect(() => {
        console.log('classlinkUser ', classlinkUser)
        if(classlinkUser.status === 'succeeded' && classlinkUser.classLinkUserProfile.status === 'active') {
            const userProfile = classlinkUser.classLinkUserProfile
            if((userProfile.role).toLowerCase() === "teacher"){
                const userDetails = { ...userProfile, selectedSection: userProfile?.sections ? userProfile?.sections[0] : {} }
                appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/teacher`,"_self")
                } else {
                    window.open(`https://www.${domainBaseUrl}/teacher`,"_self")
                }
            }else if((userProfile.role).toLowerCase() === "student"){
                appContext?.setStudentUserDetails(userProfile, "studentUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/student`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/student`,"_self")
        
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/student`,"_self")
        
                }  else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/student`,"_self")
        
                } else {
                    window.open(`https://www.${domainBaseUrl}/student`,"_self")
                }
            }else if((userProfile.role).toLowerCase() === "parent"){
                appContext?.setParentUserDetails(userProfile, "parentUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/parent`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/parent`,"_self")
        
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/parent`,"_self")
        
                } else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/parent`,"_self")
                } else {
                    window.open(`https://www.${domainBaseUrl}/parent`,"_self")
                }
            }
        } else {
            setSSOError(true)
        }
    }, [classlinkUser])

    const error = <Alert severity="error">We are unable to login now — check with your admin!</Alert>
    
    return(
        <div>
            {ssoError ? error : ''}
        </div>
        
    )
}