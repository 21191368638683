import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import AppContext from "../store/AppContext";
import { accessEmailValidation, clearSignUpData } from "../redux/features/user/user.slice";
import { Box, Button, Modal, Typography } from "@mui/material";
import error from "../assets/images/error.png";
import { clearStorage } from "../store/storage";
import {domainBaseUrl} from "../config/config"
var qs = require('qs');


const modalStyle = {
    position: 'absolute',
    // marginTop: 8,
    top:"30%",
    left: '50%',
    transform: 'translate( -50%,0)',
    width: '100%',
    maxWidth: '520px',
    // maxWidth: '422px',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // display: "flex", 
    // flexDirection: "column"
  };

const SignupTeacherLogin=({props}:any)=>{

    const dispatch = useAppDispatch();
    const appContext = useContext(AppContext);

    const signupUser = useAppSelector((state : any) => state.signupUser)
    const user = useAppSelector((state : any) => state.user)
    const hostname = window && window.location && window.location.hostname;
    const [showErr,setShowErr]=useState<any>(false)

    useEffect(() => {
        const searchParams = qs.parse(window.location.search);
        dispatch(accessEmailValidation({
            validationCode:searchParams["?emailverify"]
            // validationCode:"EEWArZRLydw"
        }))
    }, [])

    useEffect(() => {
        const  getSignupData=()=>{
                const signupData=user?.accessMailValidationObj?.data
          const newData = {...signupData.result,districtId:signupData.districtId,schoolId:signupData.schoolId,employeeId:signupData?.result?.sourcedId,sections:[],selectedSection: {}}
                return newData
            }
        if(user?.accessMailValidationObj?.status === 'succeeded') {
            console.log("succeededd")
            setShowErr(false)
            const userProfile = user?.accessMailValidationObj
            const userData = user?.accessMailValidationObj?.status === "succeeded" && getSignupData() 

            if((userData?.role)?.toLowerCase() === "teacher"){
                const userDetails = { ...userData}
                appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
                if(hostname.includes('dev')) {
                    window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('qa')) {
                    window.open(`https://qa.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('prod')) {
                    window.open(`https://prod.${domainBaseUrl}/teacher`,"_self")
                } else if(hostname.includes('uat')) {
                    window.open(`https://uat.${domainBaseUrl}/teacher`,"_self")
                } else {
                    window.open(`https://www.${domainBaseUrl}/teacher`,"_self")
                }
            }
            dispatch(clearSignUpData())
        } else if(user?.accessMailValidationObj?.status === "failed"){
            console.log("failedd")
            setShowErr(true)
            dispatch(clearSignUpData())
        }
    }, [user])


    const clickBtn = ()=>{
        setShowErr(false)
        clearStorage()
        if (hostname.includes('dev')) {
            window.open(`https://dev.${domainBaseUrl}`, "_self")
          } else if (hostname.includes('qa')) {
            window.open(`https://qa.${domainBaseUrl}`, "_self")
      
          } else if (hostname.includes('prod')) {
            window.open(`https://prod.${domainBaseUrl}`, "_self")
      
          } else if (hostname.includes('uat')) {
            window.open(`https://uat.${domainBaseUrl}`, "_self")
      
          } else {
            window.open(`https://www.${domainBaseUrl}`, "_self")
      
          }
    }

    const Loading = () => {
        return(
    
        <div className="w-100 d-flex justify-content-center align-items-center" 
          style={{
            backgroundColor:"rgba(250,250,250,0.6)",position:"absolute",height:"70vh",zIndex:1
          }}
        >
          <div className="spinner-border text-primary ">
          </div>
        </div>
        )
        }


    return(
        <div>
           {user?.accessMailValidationObj?.status ==="loading" && Loading}
           {showErr &&  <Modal  open={showErr}>
         <Box  sx={[modalStyle,{height: "35vh"}]}>
         <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign:"center",flexDirection:"column",p: 5 }}>
            <img src={error} style={{height:"100px",width:"120px",textAlign:"center"}}/>
       <Typography paragraph
        mt={1}
        sx={{ fontSize: 17, alignSelf: "center", textAlign: "center",color:"black"  }}>
       {/* {user?.accessMailValidationObj?.error} */}
       This link is not valid,Please check your admin
       </Typography>
            <Button variant="contained" style={{ color: "#fff", fontFamily: "sans-serif", textTransform: "none", backgroundColor: "#3166ba", width: "90px", height: "30px",display:"flex",justifyContent:"center",alignItems:"center",alignSelf:"center" ,cursor:"pointer",paddingBottom:"5px"}} onClick={clickBtn}>OK</Button>
        </Box>
         </Box>
    </Modal>}
        </div>
    )
}

export default SignupTeacherLogin