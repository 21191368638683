import MainLayout from '../layouts/MainLayout';
import {useEffect, useContext, useState} from "react"
import LoginScreen from '../components/LoginScreen';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { Redirect, useHistory } from 'react-router-dom';
import AppContext from '../store/AppContext';
import {domainBaseUrl} from "../config/config"
var qs = require('qs');

const base64ToJson = (base64String: any) => {
    const json = Buffer.from(base64String, "base64").toString();
    return JSON.parse(json);
}


function MainContainer(props: any) {
    console.log(domainBaseUrl,"dddddddddddddd")
    const user = useAppSelector((state : any) => state.user)
    const history = useHistory();
    const appContext = useContext(AppContext);
    const hostname = window && window.location && window.location.hostname;
    
    const searchParams = qs.parse(props.location.search);
    console.log(searchParams,"searchParamsparamss")
    if(searchParams["?code"] && searchParams["response_type"]) {
        history.push(`/classlink-sso?code=${searchParams["?code"]}`)
    } else if (searchParams["?userId"] && searchParams["classCode"]) {
        history.push(`/student-singup?userId=${searchParams["?userId"]}&classCode=${searchParams["classCode"]}`)
    } else if (searchParams["?emailverify"]) {
        console.log("emailverifyy")
        history.push(`/teacher-signup?emailverify=${searchParams["?emailverify"]}`)
    } else if(searchParams["?forgotpasswordverify"]){
        console.log("forgotpasswordverifyyy")
        history.push(`/forgot-password?forgotpasswordverify=${searchParams["?forgotpasswordverify"]}`)
    }else if(searchParams['?userDetails']){
        const details = searchParams['?userDetails']
        appContext?.setTeacherUserDetails(base64ToJson(details), "teacherUserDetails");
    }
    console.log(domainBaseUrl,"urrrrrrrrrrrr")

    useEffect(() => {
        

        const  getSignupData=()=>{
            const signupData=user.signUpData.data
            const newData = {...signupData.result,districtId:signupData.districtId,schoolId:signupData.schoolId,employeeId:signupData?.result?.sourcedId,sections:[]}
            return newData
        }

        if(!searchParams["?emailverify"] && !searchParams["?forgotpasswordverify"]){
            console.log("mailvaliddd")
            if(
                // (user.signUpData.status === "succeeded" && user.signUpData.data.result.status==="active") || 
               (user.status === "succeeded" && user.userProfile.status === 'active') ||
               (user.cleverLoginData.status === "succeeded" && user.cleverLoginData.data?.status === 'active')) {
    
                const userProfile =  user.status === "succeeded" ? user.userProfile : user.cleverLoginData.data
                // const userProfile = user.status === "succeeded" ? user.userProfile : user.signUpData.status === "succeeded" ?getSignupData() : user.cleverLoginData.data
                console.log(userProfile,"USERPROOCVKSNFV")
                if((userProfile?.role?.toLowerCase() === "teacher" || userProfile?.result?.role?.toLowerCase() === "teacher") || (userProfile?.role?.toLowerCase() === "Teacher" || userProfile?.result?.role?.toLowerCase() === "Teacher")){
                    
                    const userDetails = { ...userProfile, selectedSection: userProfile?.sections ? userProfile?.sections[0] : {} }
                    appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
                    if(hostname.includes('dev')) {
                        window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
                    } else if(hostname.includes('qa')) {
                        window.open(`https://qa.${domainBaseUrl}/teacher`,"_self")
                    } else if(hostname.includes('prod')) {
                        window.open(`https://prod.${domainBaseUrl}/teacher`,"_self")
                    } else if(hostname.includes('uat')) {
                        window.open(`https://uat.${domainBaseUrl}/teacher`,"_self")
                    } else {
                        window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
                    }
                }else if(userProfile.role === "student" || userProfile.role === "Student"){
                    appContext?.setStudentUserDetails(userProfile, "studentUserDetails");
                    if(hostname.includes('dev')) {
                        window.open(`https://dev.${domainBaseUrl}/student`,"_self")
                    } else if(hostname.includes('qa')) {
                        window.open(`https://qa.${domainBaseUrl}/student`,"_self")
            
                    } else if(hostname.includes('prod')) {
                        window.open(`https://prod.${domainBaseUrl}/student`,"_self")
            
                    }  else if(hostname.includes('uat')) {
                        window.open(`https://uat.${domainBaseUrl}/student`,"_self")
            
                    } else {
                        window.open(`https://www.${domainBaseUrl}/student`,"_self")
                    }
                }else if(userProfile.role === "Parent" || userProfile.role === "parent"){
                    appContext?.setParentUserDetails(userProfile, "parentUserDetails");
                    if(hostname.includes('dev')) {
                        window.open(`https://dev.${domainBaseUrl}/parent`,"_self")
                    } else if(hostname.includes('qa')) {
                        window.open(`https://qa.${domainBaseUrl}/parent`,"_self")

                    } else if(hostname.includes('prod')) {
                        window.open(`https://prod.${domainBaseUrl}/parent`,"_self")

                    } else if(hostname.includes('uat')) {
                        window.open(`https://uat.${domainBaseUrl}/parent`,"_self")
                    } else {
                        window.open(`https://www.${domainBaseUrl}/parent`,"_self")
                    }
                }
            }
        }
       
        
    }, [user?.status, user?.cleverLoginData.status])
    
    const teacherUserDetails = appContext?.teacherUserDetails
    const studentUserDetails = appContext?.studentUserDetails
    const parentUserDetails = appContext?.parentUserDetails
    
    if(!searchParams["?emailverify"] && !searchParams["?forgotpasswordverify"]){
      console.log("111111")
    if(teacherUserDetails?.status === 'active') {
        if(hostname.includes('dev')) {
            window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
        } else if(hostname.includes('qa')) {
            window.open(`https://qa.${domainBaseUrl}/teacher`,"_self")
        } else if(hostname.includes('prod')) {
            window.open(`https://prod.${domainBaseUrl}/teacher`,"_self")
        } else if(hostname.includes('uat')) {
            window.open(`https://uat.${domainBaseUrl}/teacher`,"_self")
        } else {
            window.open(`https://dev.${domainBaseUrl}/teacher`,"_self")
        }

    if(studentUserDetails?.status === 'active') {
        if(hostname.includes('dev')) {
            window.open(`https://dev.${domainBaseUrl}/student`,"_self")
        } else if(hostname.includes('qa')) {
            window.open(`https://qa.${domainBaseUrl}/student`,"_self")

        } else if(hostname.includes('prod')) {
            window.open(`https://prod.${domainBaseUrl}/student`,"_self")

        }  else if(hostname.includes('uat')) {
            window.open(`https://uat.${domainBaseUrl}/student`,"_self")

        } else {
            window.open(`https://www.${domainBaseUrl}/student`,"_self")
        }
    }

    if(parentUserDetails?.status === 'active') {
        if(hostname.includes('dev')) {
            window.open(`https://dev.${domainBaseUrl}/parent`,"_self")
        } else if(hostname.includes('qa')) {
            window.open(`https://qa.${domainBaseUrl}/parent`,"_self")

        } else if(hostname.includes('prod')) {
            window.open(`https://prod.${domainBaseUrl}/parent`,"_self")

        } else if(hostname.includes('uat')) {
            window.open(`https://uat.${domainBaseUrl}/parent`,"_self")
        } else {
            window.open(`https://www.${domainBaseUrl}/parent`,"_self")
        }
    }

    }
}

    return (
        <MainLayout>
           
            <LoginScreen props={props}/>
        </MainLayout>
    )
}

export default MainContainer;