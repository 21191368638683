export const environment = (): string => { 
    const hostname = window && window.location && window.location.hostname;
    console.log('hostname  ', hostname);
    if(hostname.includes('dev')) {
        return "dev"
    } else if(hostname.includes('qa')) {
        return "qa"
    } else if(hostname.includes('uat')) {
        return "uat"
    } else if(hostname.includes('local') || hostname.includes('redesign')) {
        return "qa"
    } else {
       return "prod";
    }
};
export const domainBaseUrl="aautischoolco.co"

export const getEnvironmentUrl = (url?: string) => {
    const env = environment();
    // if(env==="dev" || env==="qa") {
        if (url && url.includes('async-svc')) {
            return `https://admin-services-${env}.aauti-services.com`
        } else if(url && url.includes('core-svc')) {
            return `https://core-services-${env}.aauti-services.com`
        } else if(url && url.includes('comm-svc')) {
            return `https://comm-services-${env}.aauti-services.com`
        }else if (url && url.includes('content-svc')){
            return `https://content-services-${env}.aauti-services.com`
        }else {
            throw new Error("Invalid URL: The provided URL does not match any known service patterns.");
          }
    // }

  }
  
  export const MIX_PANEL_TOKEN = 'f23381f7fb6038bffa2be85d37aa7041'
